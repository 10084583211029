/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useState, useEffect } from "react";
import { Modal, Form as Forms, Image } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Input } from "../../../../../_metronic/_partials/controls";
import Select from "react-select";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";
import { SEARCH_MEMBER } from "../../../Member/_redux/member/memberCrud";
import { notification_type } from "../OrderReportUIHelpers";
import { CreateBrandModal } from "../../../../components/create-brand-modal/BrandDialog";
import { useLazyQuery } from "@apollo/client";
import { DatePicker, Space, TimePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";

export function OrderReportEditForm({ history, saveOrderReport, notificationInitValue }) {
  // console.log(notificationInitValue);
  const OrderReportSchema = Yup.object().shape({
    title: Yup.string().required("Judul wajib diisi"),
  });

  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [currentCallback, setCurrentCallback] = useState(() => () => null);

  // ! FETCH ALL DATA
  const [radioInput, setRadioInput] = useState("member");
  const [imageUrl, setImageUrl] = useState("");

  // * MEMBER
  const [searchMember] = useLazyQuery(SEARCH_MEMBER, {
    variables: {
      name: "",
    },
    onCompleted: ({ findMember: { items } }) => {
      currentCallback(
        items.map((member) => ({
          label: member.name,
          value: member.uuid,
          ...member,
        }))
      );
    },
    fetchPolicy: "cache-and-network",
  });

  function loadMemberOptions(inputValue, callback) {
    clearTimeout(currentTimeout);
    if (inputValue.length >= 1) {
      setCurrentTimeout(
        setTimeout(() => {
          searchMember({ variables: { name: inputValue } });
          setCurrentCallback(() => callback);
        }, 250)
      );
    } else {
      callback([]);
    }
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={notificationInitValue}
        validationSchema={OrderReportSchema}
        onSubmit={(values) => {
          saveOrderReport(values);
          // console.log(values);
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, errors, values }) => (
          <>
            <Modal.Body>
              {/* <div className="form-group row">
                <div className="col">
                  <label htmlFor="company_name">Jadwal Notif</label>
                  <br />
                  <Space direction="horizontal">
                    <DatePicker
                      defaultValue={moment()}
                      // disabledDate={disabledDate}
                      name="date"
                      onChange={(date, dateString) => {
                        setFieldValue("date", dateString);
                      }}
                    />
                    <TimePicker
                      name="time"
                      onChange={(date, dateString) => {
                        setFieldValue("time", dateString);
                      }}
                      defaultValue={moment("15:00:00", "HH:mm:ss")}
                    />
                  </Space>
                </div>
              </div> */}
              <div className="form-group row">
                <div className="col">
                  <Field
                    name="title"
                    label="Judul"
                    component={Input}
                    placeholder="Masukkan Judul notifikasi"
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <Field
                    name="image_url"
                    label="Foto"
                    component={Input}
                    placeholder="Masukkan URL foto"
                    onChange={(e) => {
                      setFieldValue("image_url", e.target.value);
                      setImageUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              {imageUrl && (
                <div className="form-group row">
                  <div className="col" style={{ height: "125px", width: "185px" }}>
                    <img
                      name="image"
                      alt="OrderReport Image"
                      style={{ objectFit: "contain" }}
                      src={imageUrl}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </div>
              )}
              <div className="form-group row">
                <div className="col">
                  <labe>Tipe</labe>
                  <Select
                    name="type"
                    placeholder="Pilih tipe..."
                    options={notification_type.map((item) => ({
                      value: item.value,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setFieldValue("type", e);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  {/* <labe>Member</labe> */}
                  <Forms.Check
                    inline
                    label="Member"
                    name="group1"
                    type="radio"
                    id="member"
                    onChange={() => {
                      setRadioInput("member");
                      setFieldValue("topics", "");
                    }}
                  />
                  <Forms.Check
                    inline
                    label="Topik"
                    name="group1"
                    type="radio"
                    id="member"
                    onChange={() => {
                      setRadioInput("topics");
                      setFieldValue("member_name", "");
                      setFieldValue("member_uuid", "");
                    }}
                  />
                  {radioInput === "member" ? (
                    <AsyncSelect
                      id="member"
                      cacheOptions={false}
                      name="member_uuid"
                      placeholder="Cari Member..."
                      noOptionsMessage={({ inputValue }) =>
                        inputValue.length >= 3 ? (
                          <span>Member tidak ditemukan</span>
                        ) : (
                          <span>Ketik 3 huruf atau lebih untuk mencari member</span>
                        )
                      }
                      loadOptions={loadMemberOptions}
                      defaultOptions={false}
                      value={
                        values.member_name && values.member_uuid
                          ? {
                              label: values.member_name,
                              value: values.member_uuid,
                            }
                          : null
                      }
                      onChange={(option) => {
                        setFieldValue("member_uuid", option.value);
                        setFieldValue("member_name", option.label);
                      }}
                      styles={{
                        control: (provided, state) => ({
                          ...provided,
                          border: errors.member_uuid
                            ? "1px solid rgb(246, 78, 96)"
                            : "1px solid #ddd",
                          marginRight: "1em",
                        }),
                        dropdownIndicator: (provided, state) => ({
                          ...provided,
                          color: errors.member_uuid ? "rgb(246, 78, 96)" : "#ddd",
                        }),
                      }}
                    />
                  ) : (
                    <Select
                      name="topics"
                      placeholder="Pilih tipe..."
                      options={notification_type.map((item) => ({
                        value: item.value,
                        label: item.name,
                      }))}
                      onChange={(e) => {
                        setFieldValue("topics", e);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <labe>Deskripsi</labe>
                  <Field
                    className="form-control"
                    name="description"
                    component="textarea"
                    rows="5"
                    placeholder="Masukkan caption notification..."
                    label="Caption"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                  />
                  <ErrorMessage
                    name="description"
                    render={(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  />
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Kirim Notifikasi
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
