import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/synchronizeMarketplace/synchronizeMarketplaceActions";
import { useSynchronizeMarketplaceUIContext } from "../SynchronizeMarketplaceUIContext";
// import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { role } from "../SynchronizeMarketplaceUIHelpers";

export function SynchronizeMarketplaceDisableDialog({ id, show, onHide }) {
  // SynchronizeMarketplace Redux state
  const dispatch = useDispatch();
  // const { actionsLoading, synchronizeMarketplaceForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.synchronizeMarketplace.actionsLoading,
  //     synchronizeMarketplaceForEdit: state.synchronizeMarketplace.synchronizeMarketplaceForEdit,
  //   }),
  //   shallowEqual
  // );

  const synchronizeMarketplaceUIContext = useSynchronizeMarketplaceUIContext();
  const synchronizeMarketplaceUIProps = useMemo(() => {
    return {
      ids: synchronizeMarketplaceUIContext.ids,
      setIds: synchronizeMarketplaceUIContext.setIds,
      queryParams: synchronizeMarketplaceUIContext.queryParams,
      setQueryParams: synchronizeMarketplaceUIContext.setQueryParams,
      openEditSynchronizeMarketplace:
        synchronizeMarketplaceUIContext.openEditSynchronizeMarketplace,
      // openDeleteSynchronizeMarketplaceDialog: synchronizeMarketplaceUIContext.openDeleteSynchronizeMarketplaceDialog,
    };
  }, [synchronizeMarketplaceUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  // useEffect(() => {
  //   setTitle(id);
  //   let _title = "";
  //   if (synchronizeMarketplaceForEdit && id) {
  //     _title = `Delete synchronizeMarketplace '${synchronizeMarketplaceForEdit.name}'`;
  //   }

  //   setTitle(_title);
  //   // eslint-disable-next-line
  // }, [synchronizeMarketplaceForEdit, actionsLoading]);
  useEffect(() => {
    setTitle(id);
    let _title = "";

    setTitle(_title);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // server call for getting SynchronizeMarketplace by id
    dispatch(
      actions.fetchSynchronizeMarketplaces(
        synchronizeMarketplaceUIProps.queryParams
      )
    );
  }, [id, dispatch]);

  // server request for saving synchronizeMarketplace
  const updateSynchronizeMarketplace = () => {
    let data = {
      uuid: id,
      is_active: false,
    };
    dispatch(actions.updateSynchronizeMarketplace(data)).then(() => onHide());
  };

  const deleteSynchronizeMarketplace = () => {
    dispatch(actions.deleteSynchronizeMarketplace(id)).then(() => {
      onHide();
      dispatch(
        actions.fetchSynchronizeMarketplaces(
          synchronizeMarketplaceUIProps.queryParams
        )
      );
    });
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan menghapus synchronizeMarketplace ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteSynchronizeMarketplace}
            className="btn btn-primary btn-elevate"
          >
            Yakin
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
