import React, { useState, useEffect, useRef } from "react";
import * as supplierCrud from "../../../MasterData/_redux/supplier/supplierCrud";
import * as categoryCrud from "../../../MasterData/_redux/category/categoryCrud";
import * as brandCrud from "../../../MasterData/_redux/brand/brandCrud";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Input } from "../../../../../_metronic/_partials/controls";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Cascader } from "antd";
import ReactSelect from "react-select";
import CreateableSelect from "react-select/creatable";
import {
  Modal,
  Form as Forms,
  Image,
  Table,
  InputGroup,
} from "react-bootstrap";
import { DeleteSkuModal } from "./DeleteSkuModal";
import Select, { components } from "react-select";
import { CreateBrandModal } from "../../../../components/create-brand-modal/BrandDialog";
import { CreateSupplierModal } from "../../../../components/create-supplier-modal/SupplierDialog";

const fileTypes = ["JPG", "PNG", "GIF"];

export function ImageProduct({ history, saveProduct }) {
  // * Validation
  // const shape = {
  //   name: Yup.string().required("Nama Produk wajib diisi"),
  //   supplier: Yup.string().required("Supplier wajib diisi"),
  //   brand: Yup.string().required("Brand wajib diisi"),
  //   weight: Yup.string().required("Berat wajib diisi"),
  //   description: Yup.string().required("Berat wajib diisi"),
  // };

  // * Back To List
  const backToList = () => {
    history.push("/product");
  };

  // * Second Variant ?
  const [haveVariant, setHaveVariant] = useState({
    variant1: false,
    variant2: false,
  });

  // * Fetch Other Data
  const [supplier, setSupplier] = useState([]);
  const [category, setCategory] = useState([]);
  const [brand, setBrand] = useState([]);
  useEffect(() => {
    supplierCrud
      .findSuppliers({
        page_number: 1,
        page_size: 1000,
        name: "",
        hp: "",
        email: "",
      })
      .then((response) => {
        setSupplier(response.items);
      });

    categoryCrud.findCategorys().then((response) => {
      setCategory(response.children);
    });

    brandCrud
      .findBrands({
        page_number: 1,
        page_size: 1000,
        is_deleted: false,
        name: "",
        sortField: "created_at",
        sortOrder: "DESC",
      })
      .then((response) => {
        setBrand(response.items);
      });
  }, []);

  // * Make Category Nested
  const generateCategory = (categoryChildren) => {
    const categories = [];
    for (const item of categoryChildren) {
      let newCategory = {
        label: item.name,
        value: item.uuid,
      };
      if (item.children) newCategory.children = generateCategory(item.children);
      categories.push(newCategory);
    }
    return categories;
  };
  let arr = [];
  if (category) {
    arr = generateCategory(category);
  }

  // * Preview Product Photo
  const [previewImage, setPreviewImage] = useState([]);

  const previewImageFunction = (value) => {
    if (value && value.length !== 0) {
      for (const item of value) {
        setPreviewImage((prevStat) => {
          if (!prevStat || prevStat.length === 0) {
            return [item];
          }
          return [...prevStat, item];
        });
      }
    }
  };

  const previewImageValue = (value) => {
    if (value.length !== 0) {
      return URL.createObjectURL(value);
    }
  };

  const deleteImageFunction = (value) => {
    console.log(value);
    let data = [...previewImage];
    const index = previewImage.findIndex((obj) => obj === value);
    data.splice(index, 1);
    setPreviewImage(data);
  };

  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState();
  const handleChange = (file) => {
    console.log(file);
    const image = URL.createObjectURL(file);
    setImagePreview(image);
    setFile(file);
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={(values) => console.log(values)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Card>
              <CardHeader title="Foto Produk">
                <CardHeaderToolbar>
                  {previewImage.length < 5 && (
                    <>
                      <label htmlFor="image">
                        <i
                          className="fa fa-plus btn btn-primary mr-2"
                          style={{ color: "#fff", marginRight: "5px" }}
                        >
                          Tambah Foto
                        </i>
                      </label>
                      <Forms.Group>
                        <Forms.File
                          id="image"
                          accept=".png, .jpg, .jpeg"
                          custom
                          multiple
                          style={{ width: "0px" }}
                          onChange={(event) => {
                            previewImageFunction(event.currentTarget.files);
                          }}
                        />

                        <ErrorMessage
                          name="image"
                          render={(msg) => (
                            <div style={{ color: "red" }}>{msg}</div>
                          )}
                        />
                      </Forms.Group>
                    </>
                  )}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col">
                      <h4>Foto Produk</h4> <br /> Format gambar .jpg .jpeg .png
                      dan ukuran minimum 300 x 300px (Untuk gambar optimal
                      gunakan ukuran minimum 700 x 700 px).
                      <br /> Pilih foto produk maksimal 5 foto. Cantumkan min. 3
                      foto yang menarik agar produk semakin menarik pembeli.
                    </div>
                  </div>
                  <div className="form-group row">
                    {previewImage &&
                      previewImage.length !== 0 &&
                      previewImage.map((item, index) => (
                        <div
                          className="col"
                          style={{
                            height: "100px",
                            width: "150px",
                          }}
                        >
                          <Image
                            src={previewImageValue(item)}
                            rounded
                            onClick={() => deleteImageFunction(item)}
                            style={{
                              height: "100%",
                              width: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      ))}
                    <br />
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-info btn-elevate float-right"
                >
                  Save
                </button>
              </CardFooter>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
