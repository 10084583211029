// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/marketplaceTransactionbook/index.html?selectedKind=Work%20on%20Columns&selectedMarketplaceTransaction=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=marketplaceTransactionbook%2Factions%2Factions-panel
import React from "react";
import {
  MarketplaceTransactionTypeCssClasses,
  MarketplaceTransactionTypeTitles,
} from "../../MarketplaceTransactionUIHelpers";

export function TypeColumnFormatter(cellContent, row) {
  return (
    <>
      <span
        className={`label label-dot label-${
          MarketplaceTransactionTypeCssClasses[row.type]
        } mr-2`}
      ></span>
      &nbsp;
      <span
        className={`font-bold font-${
          MarketplaceTransactionTypeCssClasses[row.type]
        }`}
      >
        {MarketplaceTransactionTypeTitles[row.type]}
      </span>
    </>
  );
}
