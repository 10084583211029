// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../_metronic/_helpers";
import { ORDER_STATUS } from "../../OrderLogisticUIHelpers";
import { Link } from "react-router-dom";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  {
    openEditOrderLogisticDialog,
    openDeleteOrderLogisticDialog,
    setShowModal,
    id,
    printOrderLogisticReceipt,
    printLoading,
    printLabel,
  }
) {
  return (
    <div className="d-flex">
      {/* <a
        title="View Detail"
        className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
        onClick={() => {
          // openEditOrderLogisticDialog(row.uuid)
          setShowModal(true);
          id(row.uuid);
        }}
      >
        <i class="fas fa-eye"></i>
      </a> */}
      {/* {![
        ORDER_STATUS.DRAFT,
        ORDER_STATUS.PAYMENT,
        ORDER_STATUS.ABORT,
        ORDER_STATUS.VOID,
      ].includes(row.status) && (
        <OverlayTrigger
          trigger="hover"
          overlay={<Tooltip>Print Label</Tooltip>}
        >
          <a
            title="Print"
            className={`btn btn-icon btn-light btn-hover-info btn-sm mr-2`}
            onClick={() => {
              // setShowModal(true);
              printLabel(row.uuid);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-info">
              <SVG
                src={toAbsoluteUrl("media/svg/icons/Shopping/Barcode-read.svg")}
              />
            </span>
          </a>
        </OverlayTrigger>
      )} */}
      <> </>
      <OverlayTrigger
        trigger="hover"
        overlay={<Tooltip>Print Receipt</Tooltip>}
      >
        {printLoading ? (
          <CircularProgress size={18} />
        ) : (
          <a
            title="Print"
            className={`btn btn-icon btn-light btn-hover-dark btn-sm mr-2`}
            onClick={() => {
              // setShowModal(true);
              printOrderLogisticReceipt(row.uuid);
            }}
          >
            <span className="svg-icon svg-icon-md svg-icon-dark">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode.svg")}
              />
            </span>
          </a>
        )}
      </OverlayTrigger>
      <> </>
      {/* <a
        title="Edit OrderLogistic"
        className="btn btn-icon btn-light btn-hover-primary btn-sm"
        onClick={() => {
          openEditOrderLogisticDialog(row.uuid);
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a> */}
      <Link to={`/orderLogistic/${row.uuid}/edit`}>
        <button
          title="Edit OrderLogistic"
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          // onClick={() => {
          //   openEditOrderLogisticDialog(row.uuid);
          // }}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </button>
      </Link>

      {/* <a
        title="Delete customer"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteOrderLogisticDialog(row.uuid)}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </a> */}
    </div>
  );
}
