// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { currencyFormatter } from "../../../../../../_metronic/_helpers";

export function ValueColumnFormatter(cellContent, row) {
  if (row.amount && row.discount) {
    return (
      <span>
        <strong style={{ color: "green" }}>{`${currencyFormatter(
          row.amount
        )}\n`}</strong>
        <strong style={{ color: "red" }}>{`${row.discount}%`}</strong>
      </span>
    );
  }

  if (row.amount && !row.discount) {
    return (
      <span>
        <strong style={{ color: "green" }}>{`${currencyFormatter(
          row.amount
        )}\n`}</strong>
      </span>
    );
  }
  if (row.discount && !row.amount) {
    return (
      <span>
        <strong style={{ color: "red" }}>{`${row.discount}%`}</strong>
      </span>
    );
  }
}
