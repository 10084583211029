// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/synchronizeMarketplacebook/index.html?selectedKind=Work%20on%20Columns&selectedSynchronizeMarketplace=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=synchronizeMarketplacebook%2Factions%2Factions-panel
import React from "react";
import {
  SynchronizeMarketplaceTypeCssClasses,
  SynchronizeMarketplaceTypeTitles,
} from "../../SynchronizeMarketplaceUIHelpers";

export function TypeColumnFormatter(cellContent, row) {
  return (
    <>
      <span
        className={`label label-dot label-${
          SynchronizeMarketplaceTypeCssClasses[row.type]
        } mr-2`}
      ></span>
      &nbsp;
      <span
        className={`font-bold font-${
          SynchronizeMarketplaceTypeCssClasses[row.type]
        }`}
      >
        {SynchronizeMarketplaceTypeTitles[row.type]}
      </span>
    </>
  );
}
