// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/orderReportbook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/order-report/orderReportActions";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
  toastOption,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../OrderReportUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useOrderReportUIContext } from "../OrderReportUIContext";
import { OrderReportDisableDialog } from "../order-report-disable-dialog/OrderReportDisableDialog";
import { RoleChecker } from "../../../../RoleChecker";
import { Link } from "react-router-dom";
import { ImagesModal } from "../../../../components/ImagesModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RESEND_NOTIF } from "../../_redux/order-report/orderReportCrud";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";

export function OrderReportTable() {
  const allowDelete = RoleChecker("delete", "order-report");
  const allowUpdate = RoleChecker("update", "order-report");
  // orderReport UI Context
  const orderReportUIContext = useOrderReportUIContext();
  const orderReportUIProps = useMemo(() => {
    return {
      ids: orderReportUIContext.ids,
      setIds: orderReportUIContext.setIds,
      queryParams: orderReportUIContext.queryParams,
      setQueryParams: orderReportUIContext.setQueryParams,
      openEditOrderReport: orderReportUIContext.openEditOrderReport,
      // openDeleteOrderReportDialog: orderReportUIContext.openDeleteOrderReportDialog,
    };
  }, [orderReportUIContext]);

  const [resendNotif, { loading }] = useMutation(RESEND_NOTIF, {
    onCompleted: () => {
      toast.success("Berhasil mengirim ulang notifikasi", toastOption);
      dispatch(actions.fetchOrderReport(orderReportUIProps.queryParams));
    },
    onError: (err) => {
      console.log(err);
      toast.error("Gagal mengirim ulang notifikasi", toastOption);
    },
  });

  const resendFunction = (uuid) => {
    resendNotif({
      variables: {
        uuid: uuid,
      },
    });
  };

  const [showModal, setShowModal] = useState(false);
  const [rowId, setRowId] = useState();

  const [showModalImage, setShowModalImage] = useState(false);
  const [currentImages, setCurrentImages] = useState(null);

  useEffect(() => {
    setShowModalImage(Boolean(currentImages));
  }, [currentImages]);

  // Getting curret state of orderReport list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.orderReportUser }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // orderReport Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    orderReportUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchOrderReport(orderReportUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderReportUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "title",
      text: "Judul",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "description",
      text: "Deskripsi",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "member.name",
      text: "Member",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "type",
      text: "Tipe",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusFormatter,
    },
    {
      dataField: "image_url",
      text: "Image",
      sort: true,
      formatter: (cell, row, rowIndex) => {
        return cell ? (
          <OverlayTrigger overlay={<Tooltip id="tooltip-top">Click For Detail Image!</Tooltip>}>
            <div style={{ height: "85px", width: "145px" }}>
              <img
                style={{ objectFit: "contain", cursor: "pointer" }}
                src={row.image_url}
                alt="Product Image"
                width="100%"
                height="100%"
                onClick={() => {
                  setCurrentImages(row.image_url);
                }}
                className="shadow rounded"
                object
              />
            </div>
          </OverlayTrigger>
        ) : null;
      },
      // sortCaret: sortCaret,
      // headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditOrderReport: orderReportUIProps.openEditOrderReport,
        // openDeleteOrderReportDialog: orderReportUIProps.openDeleteOrderReportDialog,
        showModal: setShowModal,
        id: setRowId,
        allowDelete,
        allowUpdate,
        resendFunction,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: orderReportUIProps.queryParams.page_size,
    page: orderReportUIProps.queryParams.page_number,
  };
  return (
    <>
      <ImagesModal
        show={showModalImage}
        images={currentImages}
        onHide={() => setCurrentImages(null)}
      />

      <OrderReportDisableDialog show={showModal} onHide={() => setShowModal(false)} id={rowId} />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={listLoading || loading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(orderReportUIProps.setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: orderReportUIProps.ids,
                //   setIds: orderReportUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
