import React from "react";
import { Modal } from "react-bootstrap";
import { OrderReportEditForm } from "./OrderReportEditForm";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import { useMutation } from "@apollo/client";
import { LoadingAnimation } from "../loading-animation/LoadingAnimation";
import { initOrderReport } from "../OrderReportUIHelpers";
import { CREATE_NOTIFICATION } from "../../_redux/order-report/orderReportCrud";

export function OrderReportEditDialog({ id, show, onHide, history, match }) {
  const [createOrderReport, { loading: createLoading }] = useMutation(CREATE_NOTIFICATION, {
    onCompleted: () => {
      toast.success("Berhasil Mengirim Notifikasi!", toastOption);
      onHide();
      history.push("/notification-user");
    },
    onError: (data) => {
      toast.error("Gagal Mengirim Notifikasi!", toastOption);
      console.log(data);
    },
  });

  const saveOrderReport = (params) => {
    createOrderReport({
      variables: {
        description: params.description,
        image_url: params.image_url,
        member_uuid: params.member_uuid,
        title: params.title,
        topic: params.topics.value,
        type: params.type.value,
      },
    });
  };

  return (
    <>
      <LoadingAnimation open={createLoading} />
      <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Kirim Notifikasi</Modal.Title>
        </Modal.Header>
        <OrderReportEditForm
          history={history}
          match={match}
          notificationInitValue={initOrderReport}
          saveOrderReport={saveOrderReport}
        />
      </Modal>
    </>
  );
}
