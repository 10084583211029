// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/notificationbook/index.html?selectedKind=Work%20on%20Columns&selectedNotification=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=notificationbook%2Factions%2Factions-panel
import React from "react";
import {
  NotificationTypeCssClasses,
  NotificationTypeTitles,
} from "../../NotificationUIHelpers";

export function TypeColumnFormatter(cellContent, row) {
  return (
    <>
      <span
        className={`label label-dot label-${
          NotificationTypeCssClasses[row.type]
        } mr-2`}
      ></span>
      &nbsp;
      <span
        className={`font-bold font-${NotificationTypeCssClasses[row.type]}`}
      >
        {NotificationTypeTitles[row.type]}
      </span>
    </>
  );
}
