import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/order-report/orderReportActions";
import { useOrderReportUIContext } from "../OrderReportUIContext";
// import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import { role } from "../OrderReportUIHelpers";

export function OrderReportDisableDialog({ id, show, onHide }) {
  // OrderReport Redux state
  const dispatch = useDispatch();
  // const { actionsLoading, orderReportForEdit } = useSelector(
  //   (state) => ({
  //     actionsLoading: state.orderReport.actionsLoading,
  //     orderReportForEdit: state.orderReport.orderReportForEdit,
  //   }),
  //   shallowEqual
  // );

  const orderReportUIContext = useOrderReportUIContext();
  const orderReportUIProps = useMemo(() => {
    return {
      ids: orderReportUIContext.ids,
      setIds: orderReportUIContext.setIds,
      queryParams: orderReportUIContext.queryParams,
      setQueryParams: orderReportUIContext.setQueryParams,
      openEditOrderReport: orderReportUIContext.openEditOrderReport,
      // openDeleteOrderReportDialog: orderReportUIContext.openDeleteOrderReportDialog,
    };
  }, [orderReportUIContext]);

  const [title, setTitle] = useState("");
  // Title couting
  // useEffect(() => {
  //   setTitle(id);
  //   let _title = "";
  //   if (orderReportForEdit && id) {
  //     _title = `Delete orderReport '${orderReportForEdit.name}'`;
  //   }

  //   setTitle(_title);
  //   // eslint-disable-next-line
  // }, [orderReportForEdit, actionsLoading]);
  useEffect(() => {
    setTitle(id);
    let _title = "";

    setTitle(_title);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // server call for getting OrderReport by id
    dispatch(actions.fetchOrderReport(orderReportUIProps.queryParams));
  }, [id, dispatch]);

  // server request for saving orderReport
  const updateOrderReport = () => {
    let data = {
      uuid: id,
      is_active: false,
    };
    dispatch(actions.updateOrderReport(data)).then(() => onHide());
  };

  const deleteOrderReport = () => {
    dispatch(actions.deleteOrderReport(id)).then(() => {
      onHide();
      dispatch(actions.fetchOrderReport(orderReportUIProps.queryParams));
    });
  };

  return (
    <Modal size="md" show={show} onHide={onHide} aria-labelledby="example-modal-sizes-title-lg">
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Anda yakin akan menghapus orderReport ini?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" onClick={onHide} className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button type="button" onClick={deleteOrderReport} className="btn btn-primary btn-elevate">
            Yakin
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
