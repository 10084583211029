// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/notificationbook/index.html?selectedKind=Work%20on%20Columns&selectedNotification=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=notificationbook%2Factions%2Factions-panel
import React from "react";
import {
  NotificationStatusCssClasses,
  NotificationStatusTitles,
  badge,
} from "../../OrderReportUIHelpers";

// export function StatusColumnFormatter(cellContent, row) {
//   const getLabelCssClasses = () => {
//     return `label label-lg label-light-${
//       NotificationStatusCssClasses[row.status]
//     } label-inline`;
//   };
//   return (
//     <span className={getLabelCssClasses()}>
//       {NotificationStatusTitles[row.status]}
//     </span>
//   );
// }

export function StatusFormatter(cellContent) {
  let data = badge(cellContent);
  return (
    <span
      className={`label label-lg label-light-${data.type} label-inline mr-4`}
    >
      {cellContent}
    </span>
  );
}
