import React from "react";
import moment from "moment"

export function LastLoginColumnFormatter(
    cellContent,
    row,
    rowIndex
) {
    if (cellContent){
        const now = moment()
        const minutes= now.diff(moment(cellContent), "minutes")
        const hours= now.diff(moment(cellContent), "hours")
        const days = now.diff(moment(cellContent), "days")
        const weeks = now.diff(moment(cellContent), "weeks")
        let datetime
        if (weeks)
            datetime = `${weeks} minggu yang lalu `
        else  if (days)
            datetime = `${days} hari yang lalu `
        else if (hours)
            datetime = `${hours} jam yang lalu`
        else if (minutes)
            datetime = `${minutes} menit yang lalu`
        else datetime = `${moment(cellContent).format('DD-MM-YYYY')}`
        return <span>
            {datetime}
        </span>
    }else {
        return <span>Belum pernah masuk</span>
    }

}
